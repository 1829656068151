import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { SzSpinner } from "@suezenv/react-theme-components";
import { AppAdminDetailsUrls, AppUrls, ErrorRoutes, Roles } from "../../constants";
import ProtectedRoute from "../protectedRoute";
import LegalPolicies from "../../pages/legals/LegalPolicies";
import CGU from "../../pages/legals/CGU";
import Cookies from "../../pages/legals/Cookies";
import Profile from "../../pages/user/profile";
import PublicWorkNew from "../../pages/publicSpacePlanner/PublicWorkNew";
import PublicWorkDetails from "../../pages/publicSpacePlanner/PublicWorkDetails";
import PublicWorkEdit from "../../pages/publicSpacePlanner/PublicWorkEdit";
import ThingsBoardDashboardPage from "../../pages/thingsboard/thingsboardDashboardPage";

const HomePage = lazy(() => import('../../pages/home'));
const SignalCreatePage = lazy(() => import('../../pages/signal/create'));
const PublicWorkList = lazy(() => import('../../pages/publicSpacePlanner/PublicWorkList'));
const SignalDetailPage = lazy(() => import('../../pages/signal/detail'));
const SignalViewPage = lazy(() => import('../../pages/signal/view'));
const RequestViewPage = lazy(() => import('../../pages/request/view'));
const RequestDetailPage = lazy(() => import('../../pages/request/detail'));
const RequestCreatePage = lazy(() => import('../../pages/request/create'));
const SignalToRequestCreateChoicePage = lazy( () => import('../../pages/request/signalRequest'))
const RequestCreateChoicePage = lazy(() => import('../../pages/request/choice'));
const AdminTerritorialManagementPage = lazy(() => import('../../pages/adminTerritorialManagement'));
const Logout = lazy(() => import('../../pages/logout'));
const AdminContracts = lazy(() => import('../admin/contracts'));
const AdminContractsCreate = lazy(() => import('../admin/contracts/create'));
const ContractDetails = lazy(() => import('../admin/contracts/details'));
const ContractAreaDetails = lazy(() => import('../admin/areas/details'));
const AdminOrganizationDetails = lazy(() => import('../admin/organizations/details'));
const AdminOperatorDetails = lazy(() => import('../admin/exploitants/details'));
const AdminUserDetails = lazy(() => import('../admin/users/details'));
const ContractCreationSuccess = lazy(() => import('../admin/contracts/forms/success'));
const ErrorManager = lazy(() => import('../../manager/error'));
const TrendReportPage = lazy(() => import('../../pages/trends/trendReportPage'));

interface IRoute {
    component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    redirectTo?: string;
    rights?: string[];
    path?: string | string[];
}

const RoutesManager = () => {

    const generatePrefixedPaths = (path: string, prefixes?: string[]): string[] => {
        if (prefixes) {
            const prefixedPaths = prefixes.map((prefix: string) => {
                return prefix.concat(path)
            });

            return prefixedPaths.concat([path]);
        }

        return [path];
    }

    const routes: IRoute[] = [
        {
            path: AppUrls.LOGOUT,
            component: Logout,
        },
        {
            path: [AppUrls.HOME, AppUrls.CARTO],
            component: HomePage,
        },
        {
            path: [AppUrls.SIGNAL_CREATE],
            component: SignalCreatePage,
        },
        {
            path: [AppUrls.SIGNAL_DETAIL],
            component: SignalDetailPage,
        },
        {
            path: [AppUrls.SIGNAL_TO_REQUEST_CREATE],
            component: SignalToRequestCreateChoicePage,
        },
        {
            path: [AppUrls.SIGNAL_VIEW],
            component: SignalViewPage,
        },
        {
            path: [AppUrls.REQUEST_CHOICE],
            component: RequestCreateChoicePage,
        },
        {
            path: [AppUrls.REQUEST_CREATE],
            component: RequestCreatePage,
        },
        {
            path: [AppUrls.REQUEST_DRAFT],
            component: RequestCreatePage,
        },
        {
            path: [AppUrls.REQUEST_DETAIL],
            component: RequestDetailPage,
        },
        {
            path: [AppUrls.REQUEST_VIEW],
            component: RequestViewPage,
        },
        {
            path: [AppUrls.POWER_BI_WORKSPACE],
            component: TrendReportPage,
        },
        {
            path: [AppUrls.THINGS_BOARD_WORKSPACE],
            component: ThingsBoardDashboardPage,
        },
        {
            path: [AppUrls.USER_PROFILE],
            component: Profile,
        },
        {
            path: [AppUrls.ADMIN_GESTION_TERRITORIALE],
            rights: [Roles.ROLE_SUPER_ADMIN_CONTRACT, Roles.ROLE_SUPER_ADMIN_ORGANIZATION],
            component: AdminTerritorialManagementPage,
        },
        {
            path: [AppUrls.ADMIN_CONTRACTS, AppUrls.ADMIN_CONTRACTS_OWN, AppUrls.ADMIN_CONTRACTS_TOFINISH],
            rights: [Roles.ROLE_SUPER_ADMIN_PORTAL_VILLAGILE],
            component: AdminContracts,
        },
        {
            path: AppUrls.ADMIN_CONTRACTS_CREATE,
            rights: [Roles.ROLE_SUPER_ADMIN_PORTAL_VILLAGILE],
            component: AdminContractsCreate,
        },
        {
            path: AppUrls.ADMIN_CONTRACTS_CREATE_SUCCESS,
            rights: [Roles.ROLE_SUPER_ADMIN_PORTAL_VILLAGILE],
            component: ContractCreationSuccess,
        },
        {
            path: AppUrls.ADMIN_CONTRACTS_DETAILS,
            rights: [Roles.ROLE_SUPER_ADMIN_PORTAL_VILLAGILE],
            component: ContractDetails,
        },
        {
            path: generatePrefixedPaths(AppAdminDetailsUrls.AREA_DETAILS, [AppUrls.ADMIN_CONTRACTS_DETAILS, AppUrls.ADMIN_GESTION_TERRITORIALE]),
            rights: [Roles.ROLE_SUPER_ADMIN_PORTAL_VILLAGILE, Roles.ROLE_SUPER_ADMIN_CONTRACT],
            component: ContractAreaDetails,
        },
        {
            path: generatePrefixedPaths(AppAdminDetailsUrls.ORGANIZATION_DETAILS, [AppUrls.ADMIN_CONTRACTS_DETAILS, AppUrls.ADMIN_GESTION_TERRITORIALE]),
            rights: [Roles.ROLE_SUPER_ADMIN_PORTAL_VILLAGILE, Roles.ROLE_SUPER_ADMIN_CONTRACT, Roles.ROLE_SUPER_ADMIN_ORGANIZATION],
            component: AdminOrganizationDetails,
        },
        {
            path: generatePrefixedPaths(AppAdminDetailsUrls.OPERATOR_DETAILS, [AppUrls.ADMIN_CONTRACTS_DETAILS, AppUrls.ADMIN_GESTION_TERRITORIALE]),
            rights: [Roles.ROLE_SUPER_ADMIN_PORTAL_VILLAGILE, Roles.ROLE_SUPER_ADMIN_CONTRACT],
            component: AdminOperatorDetails,
        },
        {
            path: generatePrefixedPaths(AppAdminDetailsUrls.USER_DETAILS, [AppUrls.ADMIN_CONTRACTS_DETAILS, AppUrls.ADMIN_GESTION_TERRITORIALE]),
            rights: [Roles.ROLE_SUPER_ADMIN_PORTAL_VILLAGILE, Roles.ROLE_SUPER_ADMIN_CONTRACT],
            component: AdminUserDetails,
        },
        {
            path: AppUrls.LEGAL_POLICIES,
            component: LegalPolicies,
        },
        {
            path: AppUrls.CGU,
            component: CGU,
        },
        {
            path: AppUrls.Cookies,
            component: Cookies,
        },
        {
            path: [ErrorRoutes.NOT_FOUND, ErrorRoutes.ACCESS_DENIED, ErrorRoutes.OOPS, ErrorRoutes.SSO_ERROR],
            component: ErrorManager,
        },
        {
            path: [AppUrls.PUBLIC_WORK_LIST],
            component: PublicWorkList,
        },
        {
            path: [AppUrls.PUBLIC_WORK_NEW],
            component: PublicWorkNew,
        },
        {
            path: [AppUrls.PUBLIC_WORK_DETAIL],
            component:PublicWorkDetails,
        },
        {
            path: [AppUrls.PUBLIC_WORK_EDIT],
            component: PublicWorkEdit,
        },
    ];

    const renderRoutes = () => {
        return routes.map((route, index) => {
            if (route.rights) {
                const redirectTo = route.redirectTo ?? AppUrls.HOME;
                return <ProtectedRoute exact
                                       path={route.path}
                                       redirectTo={redirectTo}
                                       rights={route.rights}
                                       component={route.component}
                                       key={index}
                />
            } else {
                return <Route exact
                              path={route.path}
                              component={route.component}
                              key={index}
                />
            }
        });
    }

    return (<Suspense fallback={<SzSpinner/>}>
        <Switch>
            {renderRoutes()}
            <Route render={() => (<Redirect to={AppUrls.HOME}/>)}/>
        </Switch>
    </Suspense>);
};

export default RoutesManager;
